<template>
  <div class="row">
    <va-modal
      v-model="newFAQModal"
      :hide-default-actions="true"
      :fixed-layout="true"
      class="flex xs12"
      size="large"
      :no-outside-dismiss="true"
    >
      <template #header>
        <div class="modal-header">
          <h3 class="mb-3 underline">Save Question</h3>
          <button @click="closeFaqSave" class="close-button">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </template>
      <div class="modal">
        <div class="mb-4">
          <div>
            <va-input v-model="newFAQ.question" placeholder="Question" />
          </div>
          <h3 class="mb-3 underline">Answer</h3>
          <div class="custom-html-editor mb-3">
            <vue-editor
              v-if="richText"
              :editor-toolbar="customToolbar"
              class="custom-html-editor"
              v-model="newFAQ.answer"
              placeholder="Insert a Description"
              id="createHtmlEditor"
            />
          </div>
          <h3 class="mb-3 underline">Category</h3>
          
          <h3 class="mb-3 underline">
            Category
          </h3>
          <div>
            <va-select
              v-model="newFAQ.category"
              :options="categories"
              text-by="value"
              key-by="index"
              placeholder="Select a Category"
            />
          </div>
          <h3 class="mb-3 underline">
            Order
            <span class="subtitle"
              >(The order in which the FAQ will be displayed on the page)</span
            >
          </h3>
          <div>
            <va-input
              v-model="newFAQ.order"
              placeholder="Order (You can leave this empty for alphabetized order.)"
            />
          </div>
        </div>
      </div>
      <div>
        <div>
          <va-toggle v-model="newFAQ.active" label="Active" />
        </div>
        <div>
          <va-button @click="saveNewFAQ" color="#85bb65">
            Save New FAQ
          </va-button>
          <va-button @click="closeFaqSave" color="gray">
           Close/Cancel
          </va-button>
        </div>
      </div>
    </va-modal>
    <va-modal
      v-model="showModal"
      :hide-default-actions="true"
      class="flex xs12"
      size="large"
      :fixed-layout="true"
      :no-outside-dismiss="true"
    >
    <template #header>
        <div class="modal-header">
          <h3 class="mb-3 underline">Edit Question</h3>
          <button @click="closeFaqEdit" class="close-button">
            <i class="fa fa-times"></i>
          </button>
        </div>
      </template>
      <div class="modal">
        <div class="mb-4">
          <div>
            <va-input v-model="modalData.question" placeholder="Question" />
          </div>
          <h3 class="mb-3 underline">Answer</h3>
          <div class="custom-html-editor mb-3">
            <vue-editor
              :editor-toolbar="customToolbar"
              v-if="richText"
              class="custom-html-editor"
              v-model="modalData.answer"
              placeholder="Insert a Description"
              id="updateHtmlEditor"
            />
          </div>

          <h3 class="mb-3 underline">Category</h3>
          <div>
            <va-select
              v-model="modalData.category"
              :options="categories"
              text-by="value"
              key-by="index"
              placeholder="Select a Category"
            />
          </div>
          <h3 class="mb-3 underline">
            Order
            <span class="subtitle"
              >(The order in which the FAQ will be displayed on the page)</span
            >
          </h3>
          <div>
            <va-input
              v-model="modalData.order"
              placeholder="Order (You can leave this empty for alphabetized order.)"
            />
          </div>
        </div>
        <va-toggle v-model="modalData.active" label="Active" />
      </div>

      <div :slot="'actions'">
        <va-button @click="cancelModal" color="gray"> Close/Cancel </va-button>
        <va-button @click="saveEdit" color="#85bb65">
          {{ loading ? "Saving..." : "Save Faq" }}
        </va-button>
        <va-button
          @click="deleteData"
          :style="{ float: 'right' }"
          color="#d20000"
        >
          Delete
        </va-button>
      </div>
    </va-modal>
    <div class="flex md12">
  
      <va-card :title="'FAQ'">
        <va-card v-if="loading">
          <div class="text-center">
            <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
          <va-progress-bar :value="0" :buffer="0"> Loading </va-progress-bar>
        </va-card>

        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              :value="term"
              :placeholder="'Search Questions'"
              @input="search"
              removable
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-input>
          </div>
          <div class="flex xs12 md3">
            <va-select
              id="sortBy"
              v-model="sortBy"
              @input="sortByFilter"
              placeholder="Select an Option"
              label="Sort by"
              text-by="text"
              key-by="value"
              :options="sortByOptions"
            >
              <va-icon name="fa fa-search" slot="prepend" />
            </va-select>
          </div>
          <div class="flex xs12 md3 md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
        <va-data-table
          v-if="!loading"
          :fields="fields"
          :data="filteredFaqs"
          :per-page="parseInt(perPage)"
        >
          <template slot="active" slot-scope="props">
            <va-toggle
              @input="updateFaqStatus(props.rowData, props.rowData.active)"
              v-model="props.rowData.active"
              label="Active"
              color="info"
              small
            />
          </template>
          <template slot="actions" slot-scope="props">
            <va-button small color="gray" @click="edit(props.rowData)">
              Edit
            </va-button>
            <va-button
              small
              color="danger"
              @click="remove(props.rowData)"
              class="mr-1"
            >
              Delete
            </va-button>
          </template>
        </va-data-table>
        <va-button v-if="!loading" :disabled="newFAQModal" @click="createFAQ()">
          {{ newFAQModal ? "Creating FAQ" : "Create New FAQ" }}
        </va-button>
      </va-card>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import { VueEditor } from "vue2-quill-editor";
import {OrbitSpinner} from 'epic-spinners';
import FaqUtils from "../../class/FaqUtils";
import {debounce} from 'lodash';
export default {
  name: "FAQ",
  data: function () {
    return {
      loading: false,
      term: '',
      perPage: '10',
      faqs: [],
      newFAQModal: false,
      sortBy: '',
      sortByOptions: [{text: 'Category and Order', value: 'question'}],
      perPageOptions: ['5','10', '50', '100', '250'],
      newFAQ: {
        active: false,
        question: null,
        answer: null,
        category: "",
        order: null,
      },
      richText: true,
      modalData: {},
      showModal: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ header: 1 }, { header: 2 }],
        [{ align: [] }],
        [{ font: [] }],
        [{ color: [] }, { background: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image", "code-block"],
      ],
      categories: [
        {
          index: 0,
          value: "General",
          type: "general",
        },
        {
          index: 1,
          value: "Troubleshooting",
          type: "troubleshooting",
        },
        {
          index: 2,
          value: "Account Settings",
          type: "settings",
        },
        {
          index: 3,
          value: "Billing / Subscription",
          type: "billing",
        },
        {
          index: 4,
          value: "Exam / Certification",
          type: "testing",
        },
      ],
    };
  },
  components: {
    OrbitSpinner,
    VueEditor,
  },
  async created() {
    this.getFAQs();
  },
  methods: {
    async getFAQs() {
      this.loading = true;
      this.faqs = [];
      const faqs = firebase.firestore().collection("frequentlyAskedQuestions");
      faqs.get().then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          const data = doc.data();
          data.id = doc.id;
          this.faqs.push(data);
        });
        //order alphabetical by question
        this.faqs.sort((a, b) => {
          return a.question.localeCompare(b.question);
        });
      });
      this.loading = false;
    },
    sortByFilter() {
      if (this.sortBy?.value === 'question') {
       this.faqs.sort((a, b) => {
          if (a.order && b.order) {
            return a.order - b.order || a.question.localeCompare(b.question);
          } else if (a.order) {
            return -1;
          } else if (b.order) {
            return 1;
          } else {
            return a.category.localeCompare(b.category);
          }
        });
      }else{
        this.faqs.sort((a, b) => {
          return a.question.localeCompare(b.question);
        });
      }
    },

    createFAQ() {
      //reset newFAQ object
      this.newFAQ = {
        active: false,
        question: null,
        answer: null,
        category: "",
        order: null,
      };
      this.newFAQModal = true;
    },

    deleteData() {
      this.remove(this.modalData);
    },
    closeFaqEdit() {
      this.showModal = false;
    },
    closeFaqSave() {
      this.newFAQModal = false;
    },
    remove(faq) {
      this.$swal
        .fire({
          title:
            "Are you sure you want to delete question: <u>" +
            faq.question +
            "</u>?",
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            cancelButton: "order-1 right-gap",
            confirmButton: "order-2",
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.showModal = false;
            firebase
              .firestore()
              .collection("frequentlyAskedQuestions")
              .doc(faq.id)
              .delete()
              .then(() => {
                this.getFAQs();
              });
          }
        });
    },

    cancelModal() {
      this.showModal = false;
    },
    search: debounce(function(term) {
      /* eslint-disable */
      this.term = term;
    }, 100),
    edit(faq) {
      this.modalData = faq;
      this.showModal = true;
    },
    updateFaqStatus(rowData, status) {
      const faq = firebase
        .firestore()
        .collection("frequentlyAskedQuestions")
        .doc(rowData.id);
      faq.update({
        active: status,
      });
    },
    saveEdit() {
      this.loading = true;
      const faq = firebase
        .firestore()
        .collection("frequentlyAskedQuestions")
        .doc(this.modalData.id);
      let categoryData = "";
      if (this.modalData.category.value) {
        categoryData = this.modalData.category.value;
      } else if (this.modalData.category) {
        categoryData = this.modalData.category;
      }

      FaqUtils.validateFaqAnswer(this.modalData.answer);
      if(FaqUtils.checkEmptyLineAtBottom(this.modalData.answer)){
        //alert user and  confirm if he wants to save
        this.$swal
          .fire({
            title: "Empty Line Warning",
            text: "There is an empty line at the bottom of the answer. Are you sure you want to save? (You can remove it by pressing backspace at the end of the answer)",
            showCancelButton: true,
            icon: "info",
            confirmButtonText: "Yes",
            customClass: {
              cancelButton: "order-1 right-gap",
              confirmButton: "order-2",
            },
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.updateFaq(faq, categoryData);
            } else {
              this.loading = false;
            }
          });
      }else{
        this.updateFaq(faq, categoryData);
      }
    },
    updateFaq(faqDoc, categoryData){
      faqDoc
      .update({
        active: this.modalData.active,
        question: this.modalData.question,
        answer: this.modalData.answer,
        category: categoryData,
        ...(this.modalData.order && { order: this.modalData.order }),
      })
      .then(() => {
        this.showModal = false;
        this.getFAQs();
        this.loading = false;
      });
    },
    saveNewFAQ() {
      let categoryData = "";
      if (this.newFAQ?.category?.value) {
        categoryData = this.newFAQ?.category?.value;
      }
      const data = {
        question: this.newFAQ.question,
        active: this.newFAQ.active,
        answer: this.newFAQ.answer,
        category: categoryData || "general",
        order: this.newFAQ.order,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
      firebase
        .firestore()
        .collection("frequentlyAskedQuestions")
        .add(data)
        .then(() => {
          this.newFAQModal = false;
          this.getFAQs();
        });
    },
  },

  computed: {
    filteredFaqs() {
      if (!this.term) {
        return this.faqs;
      }else{
        return this.faqs.filter((faq) => {
          return faq.question.toLowerCase().includes(this.term.toLowerCase());
        });
      }
    },
    fields() {
      return [
        {
          name: "question",
          sortField: "question",
          title: "Question",
          width: "40%",
        },
        {
          name: "category",
          title: "Category",
          sortField: "category",
          width: "20%",
        },
        {
          name: "order",
          title: "order",
          width: "20%",
        },
        {
          name: "__slot:active",
          title: "Active",
          width: "20%",
        },
        {
          name: "__slot:actions",
          dataClass: "text-right",
          width: "20%",
        },
      ];
    },
  },
};
</script>
<style>
.subtitle {
  font-size: 14px;
  color: #767676;
}
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-weight: 100;
  font-size: 1.5em;
  cursor: pointer;
}
#createHtmlEditor {
  height: 150px !important;
}

#updateHtmlEditor {
  height: 150px !important;
}
</style>
